import { useExternalUsers } from 'hooks/useExternalUsers';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectPicker } from 'rsuite';

interface ExternalUserModel {
    id: number;
    firstname: string;
    lastname: string;
}

interface ExternalUserSelectProps {
    disabled: boolean;
    previewMode: boolean;
    extSkillsIdAddition: number;
    selectedMemberId: number | undefined;
    setSelectedMemberId: React.Dispatch<React.SetStateAction<number | undefined>>;
    externalUser: ExternalUserModel | undefined;
}

const ExternalUserSelect: React.FC<ExternalUserSelectProps> = ({
    disabled,
    previewMode,
    extSkillsIdAddition,
    selectedMemberId,
    setSelectedMemberId,
    externalUser,
}) => {
    const navigate = useNavigate();
    const { externalUsers } = useExternalUsers();

    const handleChange = (value: string | null) => {
        const numValue = value ? parseInt(value) : undefined;
        setSelectedMemberId(numValue);
        if (numValue !== undefined) {
            navigate(previewMode ? `/resume/${numValue}/preview` : `/resume/${numValue}`);
        } else {
            navigate(previewMode ? `/resume/preview` : `/resume`);
        }
    };

    const userData = React.useMemo(
        () =>
            externalUsers
                .filter((user: ExternalUserModel) => user.id !== externalUser?.id)
                .map((m: ExternalUserModel) => ({
                    value: (m.id + extSkillsIdAddition).toString(),
                    label: `${m.lastname} ${m.firstname} `,
                })),
        [externalUsers, externalUser, extSkillsIdAddition],
    );

    return (
        <SelectPicker
            disabled={disabled}
            label="View as"
            placeholder="Select an external member"
            value={
                selectedMemberId !== undefined
                    ? (selectedMemberId + extSkillsIdAddition).toString()
                    : null
            }
            data={userData}
            onChange={handleChange}
            style={{ width: 300 }}
        />
    );
};

export default ExternalUserSelect;
