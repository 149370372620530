import { isExternalRouter } from 'auth/cognito';
import ExternalUserSelect from 'components/screens/ExternalUserSelect';
import { MemberModel } from 'generated-types/member';
import { SkillsExternalMemberModel } from 'generated-types/skills/external_member';
import { useCertificationsHook } from 'hooks/skills/useCertifications';
import { useSkillsHook } from 'hooks/skills/useSkills';
import { useMembers } from 'hooks/useMembers';
import { useProfile } from 'hooks/useProfile';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    FlexboxGrid,
    InputNumber,
    SelectPicker,
    TagPicker,
    Tooltip,
    Whisper,
} from 'rsuite';

import { extSkillsIdAddition } from '..';

export type SearchBarItemsType = {
    group: string;
    label: string;
    value: string;
}[];

export const SearchBar = ({
    selectedSkills,
    setSelectedSkills,
    selectedMinLevel,
    setSelectedMinLevel,
    selectedMemberId,
    setSelectedMemberId,
    previewMode,
    skillsEnabled,
    user,
    externalUser,
    selectedCertifications,
    setSelectedCertifications,
}: {
    selectedSkills: number[];
    setSelectedSkills: React.Dispatch<React.SetStateAction<number[]>>;
    selectedMinLevel: number;
    setSelectedMinLevel: React.Dispatch<React.SetStateAction<number>>;
    selectedMemberId?: number;
    setSelectedMemberId: React.Dispatch<React.SetStateAction<number | undefined>>;
    previewMode: boolean;
    skillsEnabled: boolean;
    user?: MemberModel;
    externalUser?: SkillsExternalMemberModel;
    selectedCertifications: number[];
    setSelectedCertifications: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { members } = useMembers(false, true, false);
    const useSkills = useSkillsHook(isExternalRouter);
    const { skills } = useSkills({ enabled: skillsEnabled });
    const useCertifications = useCertificationsHook(isExternalRouter);
    const { certifications } = useCertifications({ enabled: skillsEnabled });

    const searchBarItems = skills
        ?.map(skill => {
            return { ...skill, group: 'Skills' };
        })
        .concat(
            certifications?.map(certification => {
                return {
                    value: `cert-${certification.id}`,
                    label: certification.name,
                    group: 'Certifications',
                };
            }),
        );

    const { isAdmin } = useProfile();

    const skillsSelected = selectedSkills.length > 0;
    const certificationsSelected = selectedCertifications.length > 0;
    const showBackToSearchButton = (skillsSelected || certificationsSelected) && userId;
    const pickerValue = selectedSkills
        .map(skill => `skill-${skill}`)
        .concat(selectedCertifications.map(cert => `cert-${cert}`));
    return (
        <FlexboxGrid>
            <FlexboxGrid.Item>
                <SelectPicker
                    disabled={skillsSelected || certificationsSelected}
                    label="View as"
                    placeholder="Select a member"
                    value={selectedMemberId ? selectedMemberId : null}
                    data={members
                        .filter(member => member.id !== user?.id)
                        .map(m => {
                            return {
                                value: m.id,
                                label: `${m.lastname} ${m.firstname} `,
                            };
                        })}
                    onChange={value => {
                        if (value) {
                            setSelectedMemberId(value);
                            navigate(previewMode ? `/resume/${value}/preview` : `/resume/${value}`);
                        } else {
                            navigate(previewMode ? `/resume/preview` : `/resume`);
                        }
                    }}
                    style={{ width: 300 }}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item style={{ marginLeft: 10 }}>
                {isAdmin && (
                    <ExternalUserSelect
                        disabled={skillsSelected || certificationsSelected}
                        previewMode={previewMode}
                        extSkillsIdAddition={extSkillsIdAddition}
                        selectedMemberId={selectedMemberId}
                        setSelectedMemberId={setSelectedMemberId}
                        externalUser={externalUser}
                    />
                )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item style={{ marginLeft: 10 }}>
                <TagPicker
                    placeholder="Search skills and certifications"
                    data={searchBarItems ?? []}
                    groupBy="group"
                    style={{ width: 300 }}
                    value={pickerValue}
                    onChange={(selectedItems: string[] | null) => {
                        if (selectedItems) {
                            const [skills, certifications] = selectedItems.reduce(
                                (acc, item) => {
                                    if (item.startsWith('skill-')) {
                                        acc[0].push(Number(item.replace('skill-', '')));
                                    } else if (item.startsWith('cert-')) {
                                        acc[1].push(Number(item.replace('cert-', '')));
                                    }
                                    return acc;
                                },
                                [[], []] as [number[], number[]],
                            );
                            setSelectedSkills(skills);
                            setSelectedCertifications(certifications);
                            setSelectedMemberId(undefined);
                        }
                    }}
                    onClean={() => {
                        setSelectedSkills([]);
                        setSelectedCertifications([]);
                    }}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item style={{ marginLeft: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                <Whisper trigger="focus" speaker={<Tooltip>Minimum skill level</Tooltip>}>
                    <InputNumber
                        postfix="%"
                        style={{ width: 110 }}
                        value={selectedMinLevel}
                        min={0}
                        max={100}
                        step={10}
                        disabled={!skillsSelected}
                        onChange={value => {
                            const number = Number(value);
                            if (!isNaN(number) && number >= 0) {
                                setSelectedMemberId(undefined);
                                setSelectedMinLevel(number);
                            }
                        }}
                    />
                </Whisper>
            </FlexboxGrid.Item>
            {showBackToSearchButton && (
                <FlexboxGrid.Item
                    style={{ marginLeft: 10, marginTop: 'auto', marginBottom: 'auto' }}
                >
                    <Button
                        size="sm"
                        appearance="ghost"
                        onClick={() =>
                            navigate(
                                `/resume/search?skills=${encodeURIComponent(
                                    selectedSkills.join(','),
                                )} `,
                            )
                        }
                    >
                        Back to search
                    </Button>
                </FlexboxGrid.Item>
            )}
        </FlexboxGrid>
    );
};
